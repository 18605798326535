<template>
  <section class="w-full px-6 bg-white">
    <div class="w-full md:max-w-lg mx-auto pt-12 text-center">
      <h1 class="text-3xl font-bold">
        Tell us more about your company.
      </h1>
    </div>

    <div class="w-full md:max-w-sm mx-auto my-8">
      <form @submit.prevent="onSubmit">
        <div class="mt-4 mx-auto">
          <label class="vs-input--label">What platforms do you work with?</label>

          <div
            v-for="(row, index) in platforms"
            :key="index"
            class="vx-row"
          >
            <div
              v-for="platform in row"
              :key="platform.code"
              class="vx-col w-full sm:w-1/2"
            >
              <vs-checkbox
                v-model="form.platforms"
                :vs-value="platform.label"
                class="mt-3"
              >
                {{ platform.label }}
              </vs-checkbox>
            </div>
          </div>
        </div>

        <div v-if="otherSelected">
          <vs-input
            v-model="form.other"
            v-validate="'required'"
            label="What other platforms do you work with?*"
            name="other"
            class="w-full mt-4"
          />
          <span class="text-danger text-sm">{{ errors.first('other') }}</span>
        </div>

        <vs-input
          v-model="form.industry"
          label="What industry do you primarily serve?"
          name="industry"
          class="w-full mt-4"
        />

        <div class="mt-3">
          <label class="vs-input--label">How long have you been in business?*</label>
          <rj-select
            v-model="form.experience"
            v-validate="'required'"
            name="experience"
            :options="['Startup', '1-2 years', '3-5 years', '6-10 years', '11+ years']"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('experience') }}
          </span>
        </div>

        <div class="mt-3">
          <label class="vs-input--label">How many clients do you currently manage?*</label>
          <rj-select
            v-model="form.clientsAmount"
            v-validate="'required'"
            name="number of clients"
            :options="['0', '1-10', '11-25', '26-50', '51-100', '101-200', '201-500', '501-1,000', '1,000-2,500', '2,500+']"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('number of clients') }}
          </span>
        </div>

        <div class="mt-3">
          <label class="vs-input--label inline-block">
            How many clients do you plan to sign up on RevenueJump in the next three months?*
          </label>
          <rj-select
            v-model="form.clientsProspects"
            v-validate="'required'"
            name="clients prospects"
            :options="['0', '1-10', '11-25', '26-50', '51-100', '100+']"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('clients prospects') }}
          </span>
        </div>

        <div class="text-center mt-8">
          <vs-button
            size="large"
            button="submit"
          >
            Save Details
          </vs-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { chunk } from '@/utils';

export default {
  name: 'RjPartnerCompanyForm',

  data() {
    return {
      form: {
        other: '',
        platforms: [],
        industry: null,
        experience: null,
        clientsAmount: null,
        clientsProspects: null,
      },
    };
  },

  computed: {
    platforms() {
      const platforms = [
        { code: 'activecampaign', label: 'ActiveCampaign' },
        { code: 'mailchimp', label: 'Mailchimp' },
        { code: 'agile-crm', label: 'AgileCRM' },
        { code: 'ontraport', label: 'Ontraport' },
        { code: 'clickfunnels', label: 'ClickFunnels' },
        { code: 'salesforce', label: 'Salesforce' },
        { code: 'hubspot', label: 'Hubspot' },
        { code: 'sharpspring', label: 'SharpSpring' },
        { code: 'infusionsoft-keap', label: 'Infusionsoft/Keap' },
        { code: 'other', label: 'Other' },
      ];

      return chunk(platforms, 2);
    },

    otherSelected() {
      return this.form.platforms.some((code) => code === 'other');
    },
  },

  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();

      return (valid) ? this.$emit('submit', this.form) : null;
    },
  },
};
</script>

import FullPage from '@/layouts/full-page/FullPage.vue';
import Guest from '@/http/middleware/RedirectIfAuthenticated';

export default {
  name: 'RjBaseRegisterView',

  middleware: [Guest],

  render(h) {
    return h(FullPage, {
      scopedSlots: { default: () => h('router-view') },
    });
  },
};

<template>
  <div class="w-full flex flex-col sm:flex-row">
    <section class="w-full md:w-1/2 pt-12 px-6 sm:pr-10 bg-white">
      <div class="w-full sm:max-w-sm ml-auto mb-8 sm:mb-16">
        <header>
          <h1 class="text-3xl font-bold">
            <span v-if="firstMonthFree">Howdy Keap Partner!</span>
            <span v-else>Howdy partner!</span>
          </h1>
          <p>
            <span v-if="isElitePartner && firstMonthFree">You're getting your first month as an <span class="font-semibold">Elite Partner</span> for free! If you do not cancel or change your plan before the end of your free trial, it will renew at just $199/mo USD.</span>
            <span v-if="isElitePartner && !firstMonthFree">You're applying to become an <span class="font-semibold">Elite Partner</span> for just $199/mo USD.</span>
            <span v-if="!isElitePartner">You're applying to become a <span class="font-semibold">Certified Partner</span> for just $99/mo USD.</span>
          </p>
        </header>

        <form @submit.prevent="onSubmit">
          <!-- Country -->
          <div class="mt-4">
            <label class="vs-input--label">Where are you located?</label>
            <div class="mt-2 mb-1">
              <vs-radio
                v-model="form.country"
                v-validate="'required'"
                name="country"
                vs-value="US"
                class="mr-5"
              >
                United States
              </vs-radio>
              <vs-radio
                v-model="form.country"
                v-validate="'required'"
                name="country"
                vs-value="CA"
              >
                Canada
              </vs-radio>
              <br>
              <span
                v-if="!form.country && !form.valid"
                class="text-sm text-red-500"
              >
                The Country field is required
              </span>
            </div>
            <p class="text-sm">
              <a
                href="https://www.revenuejump.com/contact-us/"
                target="_blank"
                class="text-blue-400 hover:underline"
              >
                Contact us
              </a>
              if you're somewhere else!
            </p>
          </div>

          <p class="my-4 sm:mt-5 sm:mb-2">
            <span class="font-semibold">Why collect billing info now?</span>
            We only accept serious partners and this is a sign that you're committed. If we don't approve your
            application, we'll give you a 100% refund.
          </p>

          <div class="vx-row">
            <!-- First Name -->
            <div class="vx-col w-full sm:w-1/2">
              <vs-input
                v-model="form.firstName"
                v-validate="'required'"
                name="First Name"
                label="Billing First Name*"
                class="w-full mt-3"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('First Name') }}
              </span>
            </div>

            <!-- Last Name -->
            <div class="vx-col w-full sm:w-1/2">
              <vs-input
                v-model="form.lastName"
                v-validate="'required'"
                name="Last Name"
                label="Billing Last Name*"
                class="w-full mt-3"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('Last Name') }}
              </span>
            </div>
          </div>

          <div class="mt-3">
            <vs-input
              v-model="form.email"
              v-validate="'required|email'"
              name="Email"
              label="Billing Email Address*"
              class="w-full"
            />
            <span class="text-sm text-red-500">
              {{ errors.first('Email') }}
            </span>
          </div>

          <div class="mt-3">
            <vs-input
              v-model="form.phone"
              v-validate="'required'"
              v-facade="'(###) ###-####'"
              label="Phone Number*"
              name="phone number"
              class="w-full mt-3"
            />
            <span
              v-show="errors.has('phone number')"
              class="text-danger text-sm"
            >
              {{ errors.first('phone number') }}
            </span>
          </div>

          <div>
            <vs-input
              v-model="form.cardNumber"
              v-validate="'required|credit_card'"
              v-facade="'#### #### #### ####'"
              name="Card Number"
              label="Credit Card Number*"
              class="w-full mt-3"
            />
            <span class="text-sm text-red-500">
              {{ errors.first('Card Number') }}
            </span>
          </div>

          <div class="vx-row">
            <!-- Card Expiration Date -->
            <div class="vx-col w-full sm:w-1/3">
              <vs-input
                v-model="form.expiryDate"
                v-validate="'required'"
                v-facade="'##/##'"
                name="Expiration Date"
                label="MM / YY*"
                class="w-full mt-3"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('Expiration Date') }}
              </span>
            </div>

            <!-- Card CVC -->
            <div class="vx-col w-full sm:w-1/3">
              <vs-input
                v-model="form.cvc"
                v-validate="'required'"
                v-facade="'#####'"
                name="CVC"
                label="CVC*"
                class="w-full mt-3"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('CVC') }}
              </span>
            </div>

            <!-- Zip Code -->
            <div class="vx-col w-full sm:w-1/3">
              <vs-input
                v-model="form.zip"
                v-validate="'required'"
                v-facade="zipcodeMask"
                name="Zip Code"
                :label="postalCodeLabel+'*'"
                class="w-full mt-3"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('Zip Code') }}
              </span>
            </div>
          </div>

          <div class="text-sm font-semi-bold flex items-center justify-center flex-grow mt-5 mb-8">
            <feather-icon
              icon="LockIcon"
              svg-classes="w-4 h-4 mr-2"
            />
            <span class="mt-1">
              We promise to respect your
              <a
                href="https://www.revenuejump.com/privacy/"
                target="_blank"
                class="text-blue-400 hover:underline"
              >privacy</a>.
            </span>
          </div>

          <div class="flex justify-center">
            <vs-button
              size="large"
              button="submit"
            >
              {{ applyBtnLabel }}
            </vs-button>
          </div>
        </form>
      </div>
    </section>

    <section class="w-full md:w-1/2 pt-12 px-6 sm:pl-10">
      <div class="w-full sm:max-w-sm mr-auto mb-8 sm:mb-16 sm:mt-8 ">
        <h2 class="text-2xl">
          Not just smart. Really smart.
        </h2>

        <ul class="mt-5">
          <li class="flex items-center">
            <feather-icon
              icon="CheckCircleIcon"
              svg-classes="w-8 h-8 mr-3"
              :style="{ color: themeColors.rjGreen }"
            />
            <p>
              <span class="font-bold">Add a New Revenue Stream.</span>
              Upsell clients on something new and profitable.
            </p>
          </li>
          <li class="flex items-center my-3">
            <feather-icon
              icon="CheckCircleIcon"
              svg-classes="w-8 h-8 mr-3"
              :style="{ color: themeColors.rjGreen }"
            />
            <p>
              <span class="font-bold">Get Stickier.</span>
              Reduce cancellations and keep clients longer than ever before.
            </p>
          </li>
          <li class="flex items-center">
            <feather-icon
              icon="CheckCircleIcon"
              svg-classes="w-8 h-8 mr-3"
              :style="{ color: themeColors.rjGreen }"
            />
            <p>
              <span class="font-bold">Make Clients Happier.</span>
              Better performing funnels will make you smile too.
            </p>
          </li>
        </ul>

        <div class="border-t border-b py-5 px-4 mt-8 border-gray-400">
          <div class="flex items-center ml-4">
            <div class="flex items-center justify-center mr-4 w-24 h-24 overflow-hidden">
              <img
                src="@/assets/images/pages/register/ReputationX.png"
                alt="Testimonial"
                class="block w-auto"
              >
            </div>
            <div class="text-xl">
              <p class="font-bold">
                Laura Fahr
              </p>
              <p>RevenueJump Partner</p>
            </div>
          </div>

          <blockquote class="mt-4 italic">
            "We have been partnering with RevenueJump for over two years ... The tool is
            <span class="font-bold">extremely easy</span> to use and the
            <span class="font-bold">analytics are powerful.</span>"
          </blockquote>
        </div>

        <div class="mt-6 flex items-center justify-center">
          <p class="uppercase font-bold w-32 text-center mr-3">
            256 bit ssl encryption
          </p>
          <img src="@/assets/images/pages/sectigo-logo.png">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { facade } from 'vue-input-facade';
import { ELITE_PARTNER_PLAN } from '@/rj_consts';
import { colors as themeColors } from '@/../themeConfig';

export default {
  name: 'RjPartnerBillingForm',
  directives: {
    facade,
  },
  props: {
    plan: {
      type: String,
      required: true,
    },
    billingSubmitted: {
      type: Boolean,
      required: true,
    },
    firstMonthFree: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      form: {
        country: 'US',
        firstName: '',
        lastName: '',
        email: '',
        cardNumber: '',
        expiryDate: '',
        cvc: '',
        zip: '',
        phone: '',
        valid: true,
      },
      themeColors,
    };
  },

  computed: {
    applyBtnLabel() {
      return this.billingSubmitted ? 'Submitting...' : 'Apply';
    },
    isElitePartner() {
      return this.plan === ELITE_PARTNER_PLAN;
    },
    zipcodeMask() {
      if (this.form.country === 'US') {
        return '#####';
      }
      if (this.form.country === 'CA') {
        return 'XXX XXX';
      }
      return '#####';
    },
    postalCodeLabel() {
      if (this.form.country === 'US') {
        return 'ZIP';
      }
      if (this.form.country === 'CA') {
        return 'Postal Code';
      }
      return 'Zip/Postal Code';
    },
  },

  methods: {
    async onSubmit() {
      if (this.billingSubmitted) {
        return null;
      }
      const valid = await this.$validator.validateAll() && this.form.country;
      if (valid) {
        this.submitting = true;
        const expiryYear = `20${this.form.expiryDate.substring(
          this.form.expiryDate.length - 2,
        )}`;
        const expiryMonth = this.form.expiryDate.substring(0, 2);
        this.form.valid = true;
        return this.$emit('submit', {
          ...this.form,
          expiryYear,
          expiryMonth,
        });
      }
      this.form.valid = false;
      return null;
    },
  },
};
</script>

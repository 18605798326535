<template>
  <section class="w-full px-6 bg-white">
    <div class="w-full md:max-w-lg mx-auto pt-12 text-center">
      <h1 class="text-3xl font-bold mb-5">
        Are you elite material?
      </h1>
      <div class="text-red-600 font-bold text-lg">
        <p class="uppercase">
          One-time offer:
        </p>
        <p>
          Upgrade to Elite Partner for FREE the first month &mdash; just $199/mo thereafter.
        </p>
      </div>
      <p class="text-lg text-gray-600 mt-2">
        Plus, you get all the perks that only Elite Partners get:
      </p>
    </div>

    <rj-become-elite-perks class="w-full md:max-w-lg mx-auto mt-12" />

    <footer class="w-full md:max-w-lg justify-center mx-auto">
      <div class="flex flex-col items-center justify-center mt-6">
        <vs-button
          size="large"
          @click="upgrade"
        >
          Upgrade Me
        </vs-button>

        <vs-button
          type="flat"
          class="mt-2"
          @click="$emit('skip')"
        >
          Skip for now
        </vs-button>
      </div>
    </footer>
  </section>
</template>

<script>
import RjBecomeElitePerks from '@/views/partner-dashboard/subscription/modals/RjBecomeElitePerks.vue';

export default {
  name: 'RjPartnerBecomeElite',

  components: { RjBecomeElitePerks },

  methods: {
    upgrade() {
      this.$emit('submit');
    },
  },
};
</script>

<template>
  <section class="w-full px-6 bg-white">
    <div class="w-full md:max-w-lg mx-auto pt-12 text-center">
      <h1 class="text-3xl font-bold mb-5">
        What is your physical address?
      </h1>
    </div>

    <div class="w-full md:max-w-sm mx-auto my-8">
      <form @submit.prevent="onSubmit">
        <!-- Street Address -->
        <div>
          <vs-input
            v-model="form.addressStreet"
            v-validate="'required'"
            label="Street Address*"
            name="street address"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('street address')"
            class="text-danger text-sm"
          >
            {{ errors.first('street address') }}
          </span>
        </div>

        <!-- City -->
        <div>
          <vs-input
            v-model="form.addressCity"
            v-validate="'required'"
            label="City*"
            name="city"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('city')"
            class="text-danger text-sm"
          >
            {{ errors.first('city') }}
          </span>
        </div>

        <div class="vx-row">
          <!-- State -->
          <div class="vx-col w-full sm:w-2/3 mt-3">
            <label class="vs-input--label">{{ regionLabel }}*</label>
            <rj-select
              v-model="form.addressRegion"
              v-validate="'required'"
              label="name"
              name="state"
              :options="regions"
              :reduce="(region) => region.shortCode"
              :clearable="false"
              class="w-full"
            />
            <span
              v-show="errors.has('state')"
              class="text-danger text-sm"
            >
              {{ errors.first('state') }}
            </span>
          </div>

          <!-- Zip Code -->
          <div class="vx-col w-full sm:w-1/3 mt-3">
            <label class="vs-input--label">{{ postalCodeLabel }}*</label>
            <vs-input
              v-model="form.addressZip"
              v-validate="'required'"
              v-facade="zipcodeMask"
              name="zip code"
              class="w-full"
            />
            <span
              v-show="errors.has('zip code')"
              class="text-danger text-sm"
            >
              {{ errors.first('zip code') }}
            </span>
          </div>
        </div>

        <!-- Location Phone Number -->
        <div>
          <vs-input
            v-model="form.phone"
            v-validate="'required'"
            v-facade="'(###) ###-####'"
            label="Location Phone Number*"
            name="phone number"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('phone number')"
            class="text-danger text-sm"
          >
            {{ errors.first('phone number') }}
          </span>
        </div>

        <div class="text-center mt-8">
          <vs-button
            size="large"
            button="submit"
          >
            Save Address
          </vs-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { facade } from 'vue-input-facade';

export default {
  name: 'RjPartnerAddressForm',
  directives: {
    facade,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        addressStreet: this.partner.addressStreet || '',
        addressCity: this.partner.addressCity || '',
        addressRegion: this.partner.addressRegion || '',
        addressZip: this.partner.addressZip || '',
        phone: this.partner.phone || '',
      },
    };
  },

  computed: {
    countries() {
      return this.$store.getters['geography/countries'];
    },

    regions() {
      return this.countries.find((c) => c.countryShortCode === this.partner.addressCountry).regions;
    },

    zipcodeMask() {
      if (this.partner.addressCountry === 'US') {
        return '#####';
      }
      if (this.partner.addressCountry === 'CA') {
        return 'XXX XXX';
      }
      return '#####';
    },

    regionLabel() {
      if (this.partner.addressCountry === 'US') {
        return 'State';
      }
      if (this.partner.addressCountry === 'CA') {
        return 'Province';
      }
      return 'Region';
    },

    postalCodeLabel() {
      if (this.partner.addressCountry === 'US') {
        return 'ZIP';
      }
      if (this.partner.addressCountry === 'CA') {
        return 'Postal Code';
      }
      return 'Zip/Postal Code';
    },
  },

  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();

      return (valid) ? this.$emit('submit', this.form) : null;
    },
  },
};
</script>

<template>
  <section class="w-full px-6 bg-white">
    <div class="w-full md:max-w-lg mx-auto pt-12 text-center">
      <h1 class="text-3xl font-bold mb-5">
        Tell us about yourself.
      </h1>
    </div>

    <div class="w-full md:max-w-sm mx-auto my-8">
      <form @submit.prevent="onSubmit">
        <!-- Company Name -->
        <div>
          <vs-input
            v-model="form.companyName"
            v-validate="'required'"
            label="Company Name*"
            name="company name"
            class="w-full"
          />
          <span
            v-show="errors.has('company name')"
            class="text-danger text-sm"
          >
            {{ errors.first('company name') }}
          </span>
        </div>

        <div class="vx-row">
          <!-- First Name -->
          <div class="vx-col w-full sm:w-1/2">
            <vs-input
              v-model="form.firstName"
              v-validate="'required'"
              label="First Name*"
              name="first name"
              class="w-full mt-3"
            />
            <span
              v-show="errors.has('firstName')"
              class="text-danger text-sm"
            >
              {{ errors.first('first name') }}
            </span>
          </div>

          <!-- Last Name -->
          <div class="vx-col w-full sm:w-1/2">
            <vs-input
              v-model="form.lastName"
              v-validate="'required'"
              label="Last Name*"
              name="last name"
              class="w-full mt-3"
            />
            <span
              v-show="errors.has('lastName')"
              class="text-danger text-sm"
            >
              {{ errors.first('last name') }}
            </span>
          </div>
        </div>

        <!-- Email Address -->
        <div>
          <vs-input
            v-model="form.email"
            v-validate="'required|email'"
            label="Email Address*"
            name="email"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('email')"
            class="text-danger text-sm"
          >
            {{ errors.first('email') }}
          </span>
        </div>

        <div class="vx-row">
          <!-- Password -->
          <div class="vx-col w-full sm:w-1/2">
            <vs-input
              ref="password"
              v-model="form.password"
              v-validate="'required|password'"
              label="Password*"
              name="password"
              type="password"
              class="w-full mt-3"
            />
            <span
              v-show="errors.has('password')"
              class="text-danger text-sm"
            >
              {{ errors.first('password') }}
            </span>
          </div>

          <!-- Password Confirmation -->
          <div class="vx-col w-full sm:w-1/2">
            <vs-input
              v-model="form.password_confirmation"
              v-validate="'required|confirmed:password'"
              type="password"
              label="Confirm Password*"
              name="confirm password"
              class="w-full mt-3"
            />
            <span
              v-show="errors.has('confirm password')"
              class="text-danger text-sm"
            >
              {{ errors.first('confirm password') }}
            </span>
          </div>
        </div>

        <!-- Phone Number -->
        <div>
          <vs-input
            v-model="form.phone"
            v-validate="'required'"
            v-facade="'(###) ###-####'"
            label="Phone Number*"
            name="phone number"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('phone number')"
            class="text-danger text-sm"
          >
            {{ errors.first('phone number') }}
          </span>
        </div>

        <!-- Website -->
        <div>
          <vs-input
            v-model="form.website"
            v-validate="'required'"
            label="Website*"
            name="website"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('website')"
            class="text-danger text-sm"
          >
            {{ errors.first('website') }}
          </span>
        </div>

        <div class="text-center mt-8">
          <vs-button
            size="large"
            button="submit"
          >
            Save Partner Info
          </vs-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { facade } from 'vue-input-facade';

export default {
  name: 'RjPartnerDetailsForm',
  directives: {
    facade,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        companyName: '',
        firstName: this.user.firstName || '',
        lastName: this.user.lastName || '',
        email: this.user.email || '',
        password: '',
        password_confirmation: '',
        phone: this.partner.phone || '',
        website: this.partner.website || '',
      },
    };
  },

  created() {
    this.$validator.extend('password', {
      getMessage: () => 'Password must be at least 8 characters and include a letter, number, and special character.',
      validate: this.passwordValidator,
    });
  },

  methods: {
    passwordValidator(value) {
      return (value.length >= 8) // 8 chars or longer.
        && (value.match(/[a-zA-Z]/)?.length >= 1) // At least one letter.
        && (value.match(/[0-9]/)?.length >= 1) // At least one digit.
        && (value.match(/[`~!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?]/)?.length >= 1); // At least one special char.
    },
    async onSubmit() {
      const valid = await this.$validator.validateAll();

      return (valid) ? this.$emit('submit', this.form) : null;
    },
  },
};
</script>

<template>
  <div class="w-full">
    <!-- Step 1 -->
    <template v-if="step === 1">
      <rj-register-partner-step :step="1">
        <rj-partner-billing-form
          :plan="partnerPlan"
          :billing-submitted="billingSubmitted"
          :first-month-free="firstMonthFree"
          @submit="chargePartner"
        />
      </rj-register-partner-step>
    </template>

    <!-- Step 2 -->
    <template v-if="step === 2">
      <rj-register-partner-step :step="2">
        <rj-setup-confirmation
          :first-month-free="firstMonthFree"
          @submit="next"
        />
      </rj-register-partner-step>
    </template>

    <!-- Step 3 -->
    <template v-if="step === 3">
      <rj-register-partner-step :step="3">
        <rj-partner-details-form
          :partner="partner"
          :user="user"
          @submit="partnerDetails"
        />
      </rj-register-partner-step>
    </template>

    <!-- Step 4 -->
    <template v-if="step === 4">
      <rj-register-partner-step
        :step="4"
        :show-back-button="true"
        @back="previous"
      >
        <rj-partner-address-form
          :partner="partner"
          :user="user"
          @submit="partnerAddress"
        />
      </rj-register-partner-step>
    </template>

    <!-- Step 5 -->
    <template v-if="step === 5">
      <rj-register-partner-step
        :step="5"
        :show-back-button="true"
        @back="previous"
      >
        <rj-partner-company-form @submit="partnerCompany" />
      </rj-register-partner-step>
    </template>

    <!-- Step 6 -->
    <template v-if="step === 6">
      <rj-register-partner-step :step="6">
        <rj-partner-setup-completed
          :plan="partnerPlan"
          :first-month-free="firstMonthFree"
        />
      </rj-register-partner-step>
    </template>
  </div>
</template>

<script>
import { ELITE_PARTNER_PLAN } from '@/rj_consts';
import RjSetupConfirmation from '@/views/register/form-wizard/RjSetupConfirmation.vue';
import RjPartnerAddressForm from '@/views/register/form-wizard/RjPartnerAddressForm.vue';
import RjPartnerBillingForm from '@/views/register/form-wizard/RjPartnerBillingForm.vue';
import RjPartnerCompanyForm from '@/views/register/form-wizard/RjPartnerCompanyForm.vue';
import RjPartnerDetailsForm from '@/views/register/form-wizard/RjPartnerDetailsForm.vue';
import RjRegisterPartnerStep from '@/views/register/form-wizard/RjRegisterPartnerStep.vue';
import RjPartnerSetupCompleted from '@/views/register/form-wizard/RjPartnerSetupCompleted.vue';

export default {
  name: 'RjRegisterElitePartner',

  components: {
    RjSetupConfirmation,
    RjPartnerAddressForm,
    RjPartnerCompanyForm,
    RjPartnerBillingForm,
    RjPartnerDetailsForm,
    RjRegisterPartnerStep,
    RjPartnerSetupCompleted,
  },

  data() {
    return {
      step: 1,
      partnerPlan: ELITE_PARTNER_PLAN,
      notificationSent: false,
      billingSubmitted: false,
    };
  },

  computed: {
    country() {
      return this.partner?.addressCountry;
    },
    partner() {
      return this.$store.getters['register/partner'];
    },
    user() {
      return this.$store.getters['register/user'];
    },
    firstMonthFree() {
      return this.$route.meta.firstMonthFree || false;
    },
  },

  watch: {
    step() {
      // send new partner notification if registration is complete
      if (this.step === 6 && !this.notificationSent) {
        const { id } = this.partner;
        this.$store.dispatch('register/sendNewPartnerNotification', id);
        this.notificationSent = true;
      }
    },
  },

  methods: {
    async chargePartner(payload) {
      this.billingSubmitted = true;
      try {
        await this.$store.dispatch('register/createPartnerWithSubscription', { ...payload, planName: ELITE_PARTNER_PLAN, firstMonthFree: this.firstMonthFree });
        const error = this.$store.getters['register/error'];
        if (error) {
          this.$vs.notify({
            title: '', text: error, color: 'danger', time: 5000,
          });
        } else {
          this.next();
        }
      } finally {
        this.billingSubmitted = false;
      }
    },

    async partnerDetails(payload) {
      const {
        companyName,
        email,
        firstName,
        lastName,
        password,
        phone,
        website,
      } = payload;
      await Promise.all([
        this.$store.dispatch('register/updatePartner', {
          name: companyName,
          phone,
          website,
        }),
        this.$store.dispatch('register/updateUser', {
          firstName,
          lastName,
          email,
          password,
        }),
      ]);
      this.next();
    },

    async partnerAddress(payload) {
      await this.$store.dispatch('register/updatePartner', payload);
      await this.$store.dispatch('register/createInHouseAccount');
      this.next();
    },

    partnerCompany(payload) {
      try {
        this.$store.dispatch('register/setIndustryData', payload);
        this.$store.dispatch('register/sendPartnerSignupNotification');
      } catch (error) {
        console.error(error);
      }
      this.next();
    },

    next() {
      window.scrollTo(0, 0);
      this.step++;
    },

    previous() {
      window.scrollTo(0, 0);
      this.step -= (this.step === 0) ? 0 : 1;
    },
  },
};
</script>

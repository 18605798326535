<template>
  <section class="w-full min-h-screen">
    <nav class="fixed flex items-center inset w-full h-16 bg-primary text-white shadow-drop z-max">
      <div class="container">
        <div class="flex items-center">
          <div class="w-1/2">
            <img
              src="@/assets/images/logo/rj-logo-white.svg"
              alt="RevenueJump Logo"
              class="w-32"
            >
          </div>

          <template v-if="showBackButton">
            <div class="w-1/2">
              <vs-button
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-arrow-left"
                text-color="#fff"
                class="ml-auto"
                @click.prevent="$emit('back')"
              >
                Back
              </vs-button>
            </div>
          </template>
        </div>
      </div>
    </nav>

    <main class="w-full flex flex-grow pt-16 min-h-screen">
      <slot />
    </main>
  </section>
</template>

<script>
export default {
  name: 'RjRegisterPartnerStep',

  props: {
    step: {
      type: Number,
      required: false,
      default: 0,
    },

    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <section class="w-full px-6 bg-white">
    <div class="w-full md:max-w-lg mx-auto pt-12 text-center">
      <h1 class="text-3xl font-bold mb-5">
        Application, done.
      </h1>
      <p class="text-lg text-gray-600">
        Welcome! While we review your application watch this quick video from Cory. Then, check out your Partner Dashboard and add your first client.
      </p>
    </div>

    <div class="w-full md:max-w-lg mx-auto my-8 flex justify-center">
      <iframe
        src="https://player.vimeo.com/video/519778531"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      />
    </div>

    <div class="w-full md:max-w-lg mx-auto my-8 flex justify-center text-center text-lg text-gray-600">
      <p>
        <span
          v-if="!firstMonthFree"
          class="font-bold"
        >30-DAY CHALLENGE:</span> Sign up 1 high jump OR 5 low jump accounts in the next 30 days and we'll refund the partner fee you just paid!
      </p>
    </div>

    <div class="w-full flex flex-col items-center justify-center md:max-w-lg mx-auto">
      <vs-button
        size="large"
        @click="authenticate"
      >
        Partner Dashboard
      </vs-button>
    </div>
  </section>
</template>

<script>
import { ELITE_PARTNER_PLAN } from '@/rj_consts';

export default {
  name: 'RjPartnerSetupCompleted',

  props: {
    plan: {
      type: String,
      required: true,
    },
    firstMonthFree: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    isElitePartner() {
      return this.plan === ELITE_PARTNER_PLAN;
    },
  },

  methods: {
    authenticate() {
      // authenticate user somehow
      return this.$router.replace({ name: 'partner-home' });
    },
  },
};
</script>

import Cookies from 'js-cookie';
import { SESSION_COOKIE } from '@/rj_consts';

export default async function guest({ store, next }) {
  const token = Cookies.get(SESSION_COOKIE);
  const authenticated = store.getters['auth/authenticated'];

  if (token || authenticated) {
    return next({ name: 'home-view' });
  }

  return next();
}

<template>
  <section class="w-full px-6 bg-white">
    <div class="w-full md:max-w-lg mx-auto pt-12 text-center">
      <div
        class="flex justify-center items-center w-full"
      >
        <video
          class="rounded-lg w-full md:w-4/5 mx-auto"
          preload="none"
          controlsList="nodownload"
          autoplay="autoplay"
          muted
          loop
          playsinline
        >
          <source
            src="@/assets/images/pages/register/dancing-dude.mp4"
            type="video/mp4"
          >
        </video>
      </div>
      <div v-if="firstMonthFree">
        <h1 class="text-3xl font-bold my-5">
          Thanks and Congrats!
        </h1>
        <p class="text-lg text-gray-600">
          What's next? Cory will be in touch with you after Partnerkon to get your new RevenueJump partner account off the ground. In the meantime, feel free to reach out if you have any questions: <a href="mailto:cs@revenuejump.com">cs@revenuejump.com</a>
        </p>
      </div>
      <div v-else>
        <h1 class="text-3xl font-bold my-5">
          Nice move!
        </h1>
        <p class="text-lg text-gray-600">
          And thanks for applying with RevenueJump ... What a savvy decision!
        </p>
        <p class="text-lg text-gray-600 mt-1">
          Your application is in process, so let's finish it under three minutes, shall we?
        </p>

        <form
          class="mt-5"
          @submit.prevent="onSubmit"
        >
          <div class="flex items-center justify-center">
            <vs-checkbox
              v-model="accept"
              v-validate="'required:true'"
              name="accept"
              class="flex justify-center"
            >
              I have read and agree to the
            </vs-checkbox>
            <a
              href="https://www.revenuejump.com/terms-of-service/"
              target="_blank"
              class="text-blue-400 hover:underline"
            >
              Terms of Service
            </a>
          </div>
          <span
            v-if="errors.has('accept')"
            class="text-sm text-red-500"
          >
            You must agree with our Terms of Service in order to continue.
          </span>

          <vs-button
            size="large"
            button="submit"
            class="my-8"
          >
            Finish 3-Minute Application
          </vs-button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RjPartnerSetupConfirmation',
  props: {
    firstMonthFree: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      accept: false,
    };
  },

  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();

      return (valid) ? this.$emit('submit') : null;
    },
  },
};
</script>
